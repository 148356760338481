// If you don't want to use TypeScript you can delete this file!
import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import NewCampaign from "../components/new-campaign"

const Homepage: React.FC = () => (
  <Layout>
    <SEO title="New Campaign" />
    <h3
      className="text-center"
    >
      Create an email campaign to share:
    </h3>
    <NewCampaign />
  </Layout>
)

export default Homepage