import React from "react"
import containerStyles from "./index.module.css"

interface Props {
  show: boolean
}

export const Loader: React.FC<Props> = ({ show }) => {
  if (show) {
    return (
      <div className={containerStyles.loaderContainer}>
        <div className={containerStyles.loader}></div>
        Loading...
      </div>
    )
  }

  return <></>
}

export default Loader
