import React, { useState } from "react"
import containerStyles from "./index.module.css"

interface Props {
  name: string
  list: string[]
  update: (emailList: string[]) => void
  inputClass?: string
}

function checkEmailValid(email: string): boolean {
  const emailRegex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  return emailRegex.test(email)
}

function addEmail(
  list: string[],
  email: string,
  setEmailValue: React.Dispatch<React.SetStateAction<string>>
): string[] {
  const emailValid = checkEmailValid(email)

  if (emailValid && !list.includes(email)) {
    setEmailValue("")
    return [...list, email]
  }

  return list
}

function removeEmail(list: string[], email: string): string[] {
  return list.filter(e => e !== email)
}

export const MultiEmailInput: React.FC<Props> = ({
  name,
  list,
  update,
  inputClass,
}) => {
  const [emailValue, setEmailValue] = useState("")

  const updateList = (): void => {
    update(addEmail(list, emailValue, setEmailValue))
  }

  return (
    <div className="flex-grow">
      <input
        className={inputClass}
        data-invalid={emailValue.length > 0 && !checkEmailValid(emailValue)}
        type="email"
        value={emailValue}
        name={name}
        id={name}
        placeholder="Type an email and press enter to add"
        aria-label="Type an email and press enter to add"
        onKeyDown={e => {
          e.key === "Enter" && e.preventDefault()
        }}
        onKeyUp={e => {
          e.key === "Enter" && updateList()
        }}
        onBlur={() => updateList()}
        onChange={e => setEmailValue(e.currentTarget.value)}
      />
      <div className={containerStyles.emailsList}>
        {[...list].map(email => (
          <span className={containerStyles.emailBox} key={email}>
            {email}
            <button
              type="button"
              className={containerStyles.removeButton}
              onClick={() => update(removeEmail(list, email))}
              aria-label={`Remove ${email} from ${name} list`}
            >
              &times;
            </button>
          </span>
        ))}
      </div>
    </div>
  )
}

export default MultiEmailInput
