import React, { useState, useRef } from "react"
import ReCAPTCHA from "react-google-recaptcha"
import { Redirect } from "@reach/router"
import { Link } from "gatsby"
import containerStyles from "./index.module.css"

import MultiEmailInput from "../multi-email-input"
import Loader from "../loader"
import { closeIcon } from "../../images/svgs"

export const NewCampaign: React.FC = () => {
  const [to, updateTo] = useState([] as string[])
  const [cc, updateCc] = useState([] as string[])
  const [subject, updateSubject] = useState("")
  const [body, updateBody] = useState("")
  const [recaptcha, updateRecaptchaKey] = useState("")
  const [savingState, updateSavingState] = useState(false)
  const [error, updateError] = useState("")
  const [savedID, updateSavedID] = useState("")
  const recaptchaRef = useRef(null)

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const state = { to, cc, subject, body, recaptcha }

    updateSavingState(true)

    fetch(
      "https://djo8fuit2l.execute-api.us-west-2.amazonaws.com/emailAppMicroservice",
      {
        method: "post",
        body: JSON.stringify(state),
      }
    )
      .then(response => {
        return response.json()
      })
      .then(data => {
        if (data.success && data.id) {
          updateSavedID(data.id)
        } else {
          throw data.error
            ? `${data.error}. Please try again.`
            : "Unknown error occurred. Please try again."
        }
      })
      .catch(err => {
        updateSavingState(false)
        updateError(err)
        recaptchaRef.current.reset()
      })
  }

  return savedID.length === 6 ? (
    <Redirect noThrow to={`send?id=${savedID}`} />
  ) : (
    <>
      <div
        className={`
          ${containerStyles.alertBanner} 
          ${error.length <= 0 ? containerStyles.alertBannerHidden : ""}
        `}
      >
        <div>Error: {error}</div>
        <button
          className={containerStyles.closeButton}
          onClick={() => updateError("")}
        >
          <img src={closeIcon} alt="Close" />
        </button>
      </div>
      <form
        onSubmit={e => handleSubmit(e)}
        className={containerStyles.newCampaignForm}
      >
        <fieldset disabled={savingState}>
          <div className={`${containerStyles.inputContainer}`}>
            <label className={containerStyles.mainInputLabel} htmlFor="to">
              To:
            </label>
            <MultiEmailInput
              name="to"
              list={to}
              update={emailList => updateTo(emailList)}
              inputClass={containerStyles.mainInput}
            />
          </div>
          <div className={`${containerStyles.inputContainer}`}>
            <label className={containerStyles.mainInputLabel} htmlFor="cc">
              CC:
            </label>
            <MultiEmailInput
              name="cc"
              list={cc}
              update={emailList => updateCc(emailList)}
              inputClass={containerStyles.mainInput}
            />
          </div>
          <div className={`${containerStyles.inputContainer}`}>
            <label className={containerStyles.mainInputLabel} htmlFor="subject">
              Subject:
            </label>
            <div className="flex-grow">
              <input
                className={containerStyles.mainInput}
                type="text"
                name="subject"
                value={subject}
                onChange={e => updateSubject(e.currentTarget.value)}
              />
            </div>
          </div>
          <div className={containerStyles.bodyContainer}>
            <textarea
              className="textInput"
              name="body"
              rows={10}
              aria-label="Email Body"
              value={body}
              onChange={e => updateBody(e.currentTarget.value)}
            />
            <aside className={containerStyles.charCount}>
              {`${body.length} Characters`}
              {to.toString().length +
                cc.toString().length +
                subject.length +
                body.length >
                1600 &&
                " - Emails this long may have issues opening in some email clients"}
            </aside>
          </div>
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey="6LdoOQAVAAAAABRja2XmRfOmsKK3ySSR963AJ39e"
            onChange={(key: string) => updateRecaptchaKey(key)}
            onExpired={() => updateRecaptchaKey("")}
            onErrored={() =>
              updateError(
                "Captcha loading failed. Please refresh and try again."
              )
            }
            className={containerStyles.recaptchaContainer}
          />
          <div className={containerStyles.shareButtonContainer}>
            <Loader show={savingState} />
            <button
              type="submit"
              disabled={
                to.length <= 0 ||
                subject.length <= 0 ||
                body.length <= 0 ||
                recaptcha.length <= 0
              }
              className={containerStyles.shareButton}
            >
              Share &rarr;
            </button>
          </div>
          <div className={containerStyles.disclaimer}>
            <p>
              By submitting this information you are agreeing to Voice Forward's{" "}
              <Link to="/terms">Terms & Conditions</Link> and consenting to the{" "}
              <Link to="/privacy">Privacy Policy</Link>.
            </p>
            <p>
              Remember that any information you submit in this form will be
              accessable on the public internet and may be viewed by anyone.
            </p>
          </div>
        </fieldset>
      </form>
    </>
  )
}

export default NewCampaign
